import CollapsibleCard from "../components/cards/CollapsibleCard";
import MiniTitle from "../components/sections/MiniTitle";
import OverlayText from "../components/sections/OverlayText";
import SectionContainer from "../components/sections/SectionContainer";

function FAQView() {
  return (
    <SectionContainer darkMode={true} id={"faq"}>
      <div className="relative z-10 flex flex-col space-y-3">
        <MiniTitle title={"FAQs"} darkMode={true} divClass={"justify-center"} />
        <p className="z-20 items-center overflow-hidden text-center text-4xl font-bold sm:text-5xl lg:text-6xl xl:text-5xl 2xl:text-6xl">
          <span className="text-white">Questions?</span>{" "}
          <span className="text-primaryGreen">Look here.</span>
        </p>

        <OverlayText
          text={"QUESTIONS? LOOK HERE"}
          textClass={
            "-left-6 text-4xl text-white opacity-5 md:text-6xl 2xl:text-8xl"
          }
        />
      </div>

      <div className="h-10"></div>

      <div className="flex flex-col space-y-8 overflow-hidden">
        <CollapsibleCard
          question={"What services do you offer as a developer?"}
          description={
            "I offer web development, mobile app development, and AI solutions tailored to meet your specific needs."
          }
        />
        <CollapsibleCard
          question={"How do you determine project pricing?"}
          description={
            "Pricing is based on the complexity and requirements of each project, with an hourly rate for development."
          }
        />
        <CollapsibleCard
          question={"Can you help with design as well as development?"}
          description={
            "Yes, I provide UX design services that focus on creating intuitive and user-friendly interfaces."
          }
        />
        <CollapsibleCard
          question={"What is your process for starting a new project?"}
          description={
            "I begin with a consultation to understand your needs, followed by a project plan and timeline."
          }
        />
        <CollapsibleCard
          question={"How can I contact you for a project inquiry?"}
          description={
            "You can reach out through the contact form on my website or via email for any project inquiries."
          }
        />
      </div>
    </SectionContainer>
  );
}

export default FAQView;
