import phone from "../../assets/icons/phone.svg";
import mail from "../../assets/icons/mail.svg";
import linkedin from "../../assets/icons/linkedin.svg";
import ContactForm from "../components/forms/ContactForm";
import MiniTitle from "../components/sections/MiniTitle";
import OverlayText from "../components/sections/OverlayText";
import ContactTypeRow, {
  ContactType,
} from "../components/sections/ContactTypeRow";

function ContactView() {
  return (
    <div
      className="relative z-10 flex w-full flex-col space-y-16 overflow-hidden bg-primaryViolet px-4 py-8 sm:px-6 md:px-9 md:py-12 lg:px-12 lg:py-16 xl:py-20 2xl:flex-row 2xl:space-y-16 2xl:px-20 3xl:py-24 4xl:px-48"
      id="contact"
    >
      <div className="relative z-10 flex-1 flex-grow">
        <div className="relative z-10 flex flex-col space-y-3">
          <MiniTitle
            title={"Contact Me"}
            darkMode={true}
            divClass={"justify-center 2xl:justify-start"}
          />

          <p className="w-full text-center text-4xl font-bold text-white sm:text-5xl lg:text-6xl 2xl:text-left">
            <span className="">Let's</span>{" "}
            <span className="text-primaryGreen">Connect</span> & <br />
            <span className="text-primaryGreen">Collaborate</span>
          </p>
        </div>

        <OverlayText
          text={"CONTACT US"}
          textClass={
            "-left-0 lg:-left-6 text-white opacity-5 text-7xl 2xl:text-left xl:text-8xl"
          }
        />

        <p className="w-full py-12 text-center text-xl text-white 2xl:w-2/3 2xl:text-left 4xl:text-3xl">
          Get in touch! Have questions or need a quote? Fill out the form below,
          and I'll respond as soon as possible. Let’s collaborate!
        </p>

        <div className="grid grid-cols-1 space-y-6 text-white md:grid-cols-2 2xl:grid-cols-1">
          <ContactTypeRow
            icon={phone}
            text={"+39 338 295 0213"}
            type={ContactType.Phone}
          />
          <ContactTypeRow
            icon={mail}
            text={"raulpalade@outlook.com"}
            type={ContactType.Email}
          />
          <ContactTypeRow
            icon={linkedin}
            text={"linkedin.com/in/raul-palade/"}
            type={ContactType.Link}
          />
        </div>
      </div>

      <div className="flex flex-1 flex-grow flex-col space-y-6 overflow-hidden">
        <ContactForm />
      </div>
    </div>
  );
}

export default ContactView;
