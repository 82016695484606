import arrowRight from "../../../assets/icons/arrow-right.svg";

function ServiceCard({
  icon,
  title,
  description,
}: {
  icon: string;
  title: string;
  description: string;
}) {
  return (
    <div className="group relative flex w-full flex-col items-center justify-center rounded-3xl bg-cardLightViolet px-8 py-14 text-sm shadow-lg transition duration-300 hover:bg-white">
      {/* Icona in cerchio grigio */}
      <div className="flex items-center justify-center rounded-full bg-cardIconViolet transition duration-300 group-hover:bg-primaryGray 3xl:p-5">
        <img
          src={icon}
          alt="Web development icon"
          className="p-4 invert filter transition duration-300 group-hover:invert-0"
          width={120}
        />
      </div>

      {/* Titolo */}
      <p className="mt-4 text-center text-2xl font-bold text-white transition duration-300 group-hover:text-black lg:text-lg xl:text-2xl 3xl:mt-8 3xl:text-4xl">
        {title}
      </p>

      {/* Descrizione */}
      <p className="mt-2 max-w-xs overflow-hidden whitespace-normal text-center text-lg leading-relaxed text-white transition duration-300 group-hover:text-textGray lg:text-lg xl:text-2xl 3xl:mt-4 3xl:text-xl">
        {description}
      </p>

      {/* Bottone Learn More */}
      <button
        className="mt-4 flex items-center font-semibold text-white transition duration-300 hover:underline focus:outline-none group-hover:text-primaryBlack 3xl:mt-6"
        aria-label="Learn more about web development"
      >
        Learn more
        <img
          src={arrowRight}
          alt=""
          className="invert filter transition duration-300 group-hover:invert-0 3xl:ml-2"
          width={20}
        />
      </button>
    </div>
  );
}

export default ServiceCard;
