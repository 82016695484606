import mockup1 from "../../assets/images/mockup1.png";
import HeaderButton from "../components/buttons/HeaderButton";
import ProjectCard from "../components/cards/ProjectCard";
import MiniTitle from "../components/sections/MiniTitle";
import OverlayText from "../components/sections/OverlayText";
import SectionContainer from "../components/sections/SectionContainer";

function ProjectsView() {
  return (
    <SectionContainer darkMode={true} id={"projects"}>
      <div className="relative z-10 flex flex-col space-y-3">
        <MiniTitle
          title={"Services"}
          darkMode={true}
          divClass={"justify-center lg:justify-start"}
        />
        <div className="flex justify-center lg:justify-between">
          <p className="text-4xl font-bold sm:text-5xl lg:text-6xl">
            <span className="text-white">My</span>{" "}
            <span className="text-primaryGreen">Latest Projects</span>
          </p>
          <div className="z-30">
            <HeaderButton title={"View all projects"} darkBg={true} />
          </div>
        </div>

        <OverlayText
          text={"LATEST PROJECTS"}
          textClass={
            "-left-0 lg:-left-6 text-white opacity-5 text-7xl lg:text-left xl:text-8xl"
          }
        />
      </div>
      <div className="h-10"></div>

      <div className="flex flex-col space-y-8 overflow-hidden">
        <ProjectCard
          image={mockup1}
          tags={["UI/UX", "App", "Firebase"]}
          title={"Food Recipe"}
          description={
            "Explore a vast collection of delicious recipes curated by renowned chefs. From appetizers to desserts, find inspiration for every occasion and elevate your cooking skills with step-by-step instructions and helpful tips!"
          }
          reverse={false}
        />
        <ProjectCard
          image={mockup1}
          tags={["UI/UX", "Web", "Firebase"]}
          title={"DAP Rent"}
          description={
            "Get everything for your special events! From elegant tableware to stylish tables and chairs, explore a wide range of catering supplies for weddings and celebrations. Make planning simple and create unforgettable moments!"
          }
          reverse={true}
        />
      </div>
    </SectionContainer>
  );
}

export default ProjectsView;
