import ButtonSpinner from "../components/animated/ButtonSpinner";
import Scroller from "../components/animated/Scroller";
import Navbar from "../components/sections/Navbar";
import avatarDesktop from "../../assets/images/avatarDesktop.svg";
import avatarMobile from "../../assets/images/avatarMobile.svg";
import { useRef, useState, useEffect } from "react";

function HomeView() {
  const buttonSpinnerRef = useRef<HTMLDivElement>(null);
  const [spinnerWidth, setSpinnerWidth] = useState(0);

  useEffect(() => {
    if (buttonSpinnerRef.current) {
      const width = buttonSpinnerRef.current.offsetWidth;
      setSpinnerWidth(width);
    }
  }, []);

  return (
    <div className="gradient relative flex min-h-screen flex-col overflow-hidden">
      <Navbar />
      <div className="z-10 flex h-full flex-col items-center justify-end px-4 sm:px-6 md:px-9 lg:px-12 xl:px-20 4xl:px-36">
        <div className="flex w-full flex-col items-center justify-center space-y-2 text-primaryLime xl:space-y-4 3xl:-mb-12 4xl:-mb-20">
          <div className="-mb-10 flex w-full items-end justify-end 3xl:hidden">
            <ButtonSpinner />
          </div>
          <div className="flex items-center space-x-2">
            <div className="inline-block w-4 border-t-2 border-primaryViolet"></div>
            <p className="text-xl md:text-3xl 2xl:text-2xl 4xl:text-4xl">
              Hello
            </p>
          </div>

          <div className="flex w-full items-center justify-center 3xl:justify-between">
            <div
              className="hidden 3xl:block"
              style={{ width: `${spinnerWidth}px` }}
            ></div>
            <div className="small-devices:text-4xl mini-devices:text-2xl flex flex-col text-center text-4xl xs:text-6xl md:text-7xl 4xl:text-8xl">
              <p>
                I'm <span className="text-primaryViolet underline">Raul,</span>
              </p>
              <p>Software Engineer</p>
            </div>
            <div className="hidden 3xl:block" ref={buttonSpinnerRef}>
              <ButtonSpinner />
            </div>
          </div>
        </div>

        <img
          src={window.innerWidth >= 760 ? avatarDesktop : avatarMobile}
          className="lg:max-h-160 mini-devices:max-h-64 xs:max-h-144 z-20 max-h-96 w-full 2xl:w-1/3 4xl:w-2/5"
          alt="Avatar"
        />
      </div>
      <Scroller />
    </div>
  );
}

export default HomeView;
