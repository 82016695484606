function MiniTitle({
  title,
  darkMode,
  divClass,
}: {
  title: string;
  darkMode: boolean;
  divClass: string;
}) {
  return (
    <div className={`flex w-full items-center ${divClass} space-x-2`}>
      <div
        className={`inline-block w-4 border-t-2 ${darkMode ? "border-primaryGreen" : "border-primaryViolet"}`}
      ></div>
      <p
        className={`text-center text-sm font-bold ${darkMode ? "text-white" : "text-primaryBlack"} sm:text-xl lg:text-2xl`}
      >
        {title}
      </p>
    </div>
  );
}

export default MiniTitle;
