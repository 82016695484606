import { Fragment, useEffect, useState } from "react";
import divider from "../../../assets/icons/radius_star.svg";

function Scroller() {
  const [scrollerContent] = useState<string[]>([
    "Frontend Development",
    "Backend Development",
    "Machine Learning and Data Science",
    "Native Apps",
    "Hybrid Apps",
    "Database",
  ]);

  useEffect(() => {
    const handleAnimation = () => {
      if (!window.matchMedia("(prefers-reduced-motion: reduce)").matches) {
        // Se non è impostata la preferenza per la riduzione del movimento, aggiungi la classe di animazione
        const animatedItems = document.querySelectorAll(".scroller");
        animatedItems.forEach((item) => {
          item.setAttribute("data-animated", "true");
        });
      }
    };

    handleAnimation();
  }, []);

  // Duplicare il contenuto per il ciclo
  const duplicatedContent = [...scrollerContent, ...scrollerContent];

  return (
    <div
      className="scroller z-20 w-full bg-primaryGreen text-lg text-primaryBlack lg:text-xl 3xl:text-2xl 4xl:text-3xl"
      data-speed="slow"
    >
      <ul className="tag-list scroller__inner flex items-center justify-center space-x-2 lg:space-x-4 3xl:space-x-8">
        {duplicatedContent.map((item, index) => (
          <Fragment key={index}>
            <li>
              <span>{item}</span>
            </li>
            {index < duplicatedContent.length - 1 && (
              <li>
                <img
                  src={divider}
                  className="divider w-5 3xl:w-10"
                  alt="Divider"
                />
              </li>
            )}
          </Fragment>
        ))}
      </ul>
    </div>
  );
}

export default Scroller;
