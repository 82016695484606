import HeaderButton from "../buttons/HeaderButton";

function ProjectCard({
  image,
  tags,
  title,
  description,
  reverse,
}: {
  image: any;
  tags: string[];
  title: string;
  description: string;
  reverse: boolean;
}) {
  return (
    <div
      className={`flex w-full flex-col space-y-4 rounded-3xl bg-cardLightViolet p-3 ${reverse ? "2xl:flex-row-reverse" : "2xl:flex-row"} 2xl:space-y-0 2xl:p-6`}
    >
      {/* HALF 1 */}
      <div className="2xl:basis-1/2">
        <img
          src={image}
          alt="mockup"
          className="transform rounded-3xl transition-transform duration-300 ease-in-out hover:scale-105"
        />
      </div>

      {/* HALF 2 */}
      <div className="flex basis-1/2 flex-col items-center justify-center space-y-3 text-xs 2xl:items-start 2xl:space-y-6 2xl:pl-12 2xl:text-xl">
        <div className="flex space-x-1 2xl:space-x-4">
          {tags.map((tag, index) => (
            <div
              key={index}
              className="rounded-full bg-primaryGreen px-4 py-1 text-sm xs:text-base sm:text-lg md:text-xl 2xl:py-2"
            >
              <p className="text-clip">{tag}</p>
            </div>
          ))}
        </div>
        <p className="text-3xl font-bold text-white 2xl:text-4xl">{title}</p>
        <p className="text-center text-base text-white 2xl:w-3/4 2xl:text-left 2xl:text-lg">
          {description}
        </p>
        <div className="z-30 hidden 2xl:block">
          <HeaderButton title={"View details"} darkBg={true} />
        </div>
      </div>
    </div>
  );
}

export default ProjectCard;
