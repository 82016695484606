function SectionContainer({
  darkMode,
  children,
  id,
}: {
  darkMode: boolean;
  children: React.ReactNode;
  id: string;
}) {
  return (
    <div
      className={`relative z-10 flex w-full flex-col overflow-hidden ${darkMode ? "bg-primaryViolet" : "bg-white"} px-4 py-8 sm:px-6 md:px-9 md:py-12 lg:px-12 lg:py-16 xl:px-20 xl:py-20 3xl:py-24 4xl:px-36`}
      id={id}
    >
      {children}
    </div>
  );
}

export default SectionContainer;
