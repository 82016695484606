function ButtonSpinner() {
  return (
    <div className="outer-circle">
      <svg viewBox="0 0 120 120" className="svg-circle">
        <path
          id="circlePath"
          d="M 60, 60 m -40, 0 a 40,40 0 1,1 80,0 a 40,40 0 1,1 -80,0"
          fill="none"
        />
        <text textAnchor="middle">
          <textPath
            href="#circlePath"
            id="textPath"
            className="circle-text"
            startOffset="50%"
          >
            &nbsp;● Hire Me ● Hire Me ● Hire Me
          </textPath>
        </text>
      </svg>
      <div className="inner-circle"></div>
    </div>
  );
}

export default ButtonSpinner;
