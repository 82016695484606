import graduation from "../../assets/icons/graduation.png";
import suitcase from "../../assets/icons/suitcase.png";
import ExperienceCard from "../components/cards/ExperienceCard";
import MiniTitle from "../components/sections/MiniTitle";
import OverlayText from "../components/sections/OverlayText";
import SectionContainer from "../components/sections/SectionContainer";

function WorkEducationView() {
  return (
    <SectionContainer darkMode={false} id={"work-education"}>
      <div className="relative z-10 flex flex-col space-y-3">
        <MiniTitle
          title={"Education & Work"}
          darkMode={false}
          divClass={"justify-center"}
        />
        <p className="z-20 items-center overflow-hidden text-center text-4xl font-bold sm:text-5xl lg:text-6xl xl:text-5xl 2xl:text-6xl">
          <span className="text-primaryBlack">My</span>{" "}
          <span className="text-primaryViolet">Education</span> &{" "}
          <span className="text-primaryViolet">Work Experience</span>
        </p>

        <OverlayText
          text={"My Education & Work Experience"}
          textClass={
            "-left-6 text-4xl text-primaryGray opacity-40 md:text-6xl 2xl:text-8xl"
          }
        />
      </div>

      <div className="h-10"></div>

      <div className="flex flex-col space-y-6 overflow-hidden xl:flex-row xl:justify-between xl:space-x-6 xl:space-y-0 3xl:space-x-12">
        <ExperienceCard
          icon={graduation}
          title={"Education"}
          educationEntry={[
            {
              place: "University of Turin",
              detail: "Bachelor Degree in Computer Science",
              years: "2018 - 2022",
            },
            {
              place: "University of Turin",
              detail: "Master Degree in Computer Science",
              years: "2022 - 2024",
            },
          ]}
        />

        <ExperienceCard
          icon={suitcase}
          title={"Work Experience"}
          educationEntry={[
            {
              place: "Freelancer",
              detail: "Software Engineer",
              years: "2018 - 2022",
            },
            {
              place: "Spindox",
              detail: "Mobile Developer",
              years: "2022 - 2023",
            },
          ]}
        />
      </div>
    </SectionContainer>
  );
}

export default WorkEducationView;
