function PrimaryButton({
  title,
  onClick,
}: {
  title: string;
  onClick: () => void;
}) {
  return (
    <button
      className="flex w-full justify-center rounded-full bg-primaryGreen p-4 text-xl font-bold text-primaryBlack shadow-sm hover:bg-primaryGray"
      onClick={onClick}
    >
      {title}
    </button>
  );
}

export default PrimaryButton;
