import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import { Toaster } from "react-hot-toast";

import { NotFoundView } from "../presentation/views/NotFoundView";
import RootView from "../presentation/views/RootView";
import AnimatedCursor from "react-animated-cursor";
import TermsConditions from "../presentation/views/TermsConditions";
import PrivacyPolicy from "../presentation/views/PrivacyPolicy";

function App() {
  return (
    <Router>
      <>
        <AnimatedCursor
          innerSize={8}
          outerSize={35}
          innerScale={1}
          outerScale={2}
          outerAlpha={0}
          innerStyle={{
            backgroundColor: "var(--cursor-color)",
          }}
          outerStyle={{
            border: "3px solid var(--cursor-color)",
          }}
        />

        <Routes>
          <Route path="*" element={<NotFoundView />} />
          <Route path="/" element={<RootView />} />
          <Route path="/terms-and-conditions" element={<TermsConditions />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
        </Routes>
      </>
      <Toaster
        position="bottom-center"
        reverseOrder={false}
        toastOptions={{
          success: {
            duration: 3000,
          },
        }}
      />
    </Router>
  );
}

export default App;
