import SectionContainer from "./SectionContainer";
import linkedin from "../../../assets/social/linkedin.svg";
import github from "../../../assets/social/github.svg";
import instagram from "../../../assets/social/instagram.svg";
import whatsapp from "../../../assets/social/whatsapp.svg";
import x from "../../../assets/social/x.svg";
import { Link } from "react-router-dom";
import logo from "../../../assets/logo-full.png";

function Footer() {
  return (
    <SectionContainer darkMode={false} id={"footer"}>
      <div className="relative z-10 flex flex-col">
        <div className="flex justify-center lg:justify-between">
          <p className="z-20 text-4xl font-bold text-primaryBlack sm:text-5xl lg:text-6xl">
            Let's <span className="text-primaryViolet">Connect</span> there
          </p>
        </div>
      </div>

      <div className="border-1 my-10 w-full rounded-full border-secondaryGray"></div>

      <div className="grid grid-cols-12 gap-y-8 lg:flex-row lg:space-y-0">
        <div className="col-span-12 flex-col space-y-8 text-center xl:col-span-6 xl:text-left">
          <img src={logo} alt="logo" className="w-64" />
          <p className="xl:w-2/3">
            Meet Raul, the developer who transforms ideas into amazing websites
            and apps! With a knack for making tech fun, he’s here to sprinkle
            creativity into every project!
          </p>
          <div className="flex justify-center space-x-4 xl:justify-start">
            <SocialIcon
              icon={linkedin}
              link={"https://linkedin.com/in/raul-palade/"}
            />
            <SocialIcon icon={whatsapp} link={"https://wa.me/3382950213"} />
            <SocialIcon icon={github} link={"https://github.com/RaulPalade"} />
            <SocialIcon icon={x} link={"https://x.com/PaladeRaul"} />
            <SocialIcon
              icon={instagram}
              link={"https://www.instagram.com/raulpalade/"}
            />
          </div>
        </div>
        <FooterColumn
          title={"Navigation"}
          values={["Home", "Services", "About", "Projects", "Blogs", "FAQs"]}
          classes={"text-left"}
        />
        <FooterColumn
          title={"Contact"}
          values={[
            "+39 338 295 0213",
            "www.raulpalade.com",
            "raulpalade@outlook.com",
            "Turin, 10145, Italy",
          ]}
          classes={"text-right"}
        />
      </div>

      <div className="border-1 my-10 rounded-full border-secondaryGray"></div>

      <div className="flex flex-col-reverse items-center justify-center space-y-3 space-y-reverse text-xs xs:text-sm sm:text-base md:text-lg 2xl:flex-row 2xl:justify-between 2xl:space-y-0 2xl:text-xl">
        <div className="flex flex-col items-center justify-center sm:space-x-1 lg:flex-row">
          <p>Copyright &copy;2024 Raul Palade.</p>
          <p>P.IVA 13087420017</p>
        </div>
        <p>
          <Link to="/terms-and-conditions" className="hover:text-primaryViolet">
            User Terms & Conditions
          </Link>
          {" | "}
          <Link to="/privacy-policy" className="hover:text-primaryViolet">
            Privacy Policy
          </Link>
        </p>
      </div>
    </SectionContainer>
  );
}

const FooterColumn: React.FC<{
  title: string;
  values: string[];
  classes: string;
}> = ({ title, values, classes }) => {
  return (
    <div className="col-span-6 flex flex-col space-y-8 xl:col-span-3">
      <p
        className={`font-bold text-primaryViolet 2xl:text-2xl ${classes} xl:text-left`}
      >
        {title}
      </p>
      <div className="flex flex-col space-y-6 text-primaryBlack 2xl:text-lg">
        {values.map((value, index) => (
          <p
            className={`text-sm sm:text-base ${classes} xl:text-left`}
            key={index}
          >
            {value}
          </p>
        ))}
      </div>
    </div>
  );
};

const SocialIcon: React.FC<{
  icon: any;
  link: string;
}> = ({ icon, link }) => {
  return (
    <div className="rounded-full bg-primaryViolet p-1">
      <a href={link} target="_blank" rel="noreferrer">
        <img src={icon} alt="social icon" className="w-12 p-1" />
      </a>
    </div>
  );
};

export default Footer;
