export enum ContactType {
  Phone = "phone",
  Email = "email",
  Link = "link",
  Normal = "normal",
}

function ContactTypeRow({
  icon,
  text,
  type,
}: {
  icon: any;
  text: string;
  type: ContactType;
}) {
  const handleClick = () => {
    switch (type) {
      case ContactType.Phone:
        window.location.href = `tel:${text}`;
        break;
      case ContactType.Email:
        window.location.href = `mailto:${text}`;
        break;
      case ContactType.Link:
        const formattedLink =
          text.startsWith("http://") || text.startsWith("https://")
            ? text
            : `https://${text}`;

        window.open(formattedLink, "_blank");
        break;

      case ContactType.Normal:
        break;
      default:
        break;
    }
  };

  return (
    <div className="flex cursor-pointer items-center space-x-4">
      <div className="rounded-full bg-white p-3">
        <img src={icon} alt="check" className="w-7 xl:w-9" />
      </div>
      <p
        onClick={handleClick}
        className="text-xl transition duration-300 group-hover:text-white md:text-lg lg:text-xl xl:text-2xl 2xl:text-2xl 4xl:text-3xl"
      >
        {text}
      </p>
    </div>
  );
}

export default ContactTypeRow;
