import { useState } from "react";
import plus from "../../../assets/icons/plus.svg";
import minus from "../../../assets/icons/minus.svg";

function CollapsibleCard({
  question,
  description,
}: {
  question: string;
  description: string;
}) {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDiv = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div
      className={`flex w-full flex-col justify-center rounded-3xl ${
        isOpen
          ? "bg-primaryGreen text-primaryBlack"
          : "bg-cardLightViolet text-white"
      } px-8 py-8 transition-colors duration-300 ease-in-out`}
      onClick={toggleDiv}
    >
      <div className="flex items-center justify-between">
        <p className="font-bold sm:text-xl md:text-2xl 2xl:text-3xl 4xl:text-4xl">
          {question}
        </p>
        <img
          src={isOpen ? minus : plus}
          alt="arrow"
          className={`w-10 transition-transform duration-300 ease-in-out ${
            isOpen ? "" : "invert filter"
          }`}
        />
      </div>

      <div
        className={`overflow-hidden transition-all duration-500 ease-in-out ${
          isOpen
            ? "mt-8 max-h-96 scale-y-100 opacity-100"
            : "max-h-0 scale-y-0 opacity-0"
        } origin-top transform`}
      >
        <div
          className={`rounded-lg shadow-md transition-colors duration-500 ease-in-out ${
            isOpen ? "text-primaryBlack" : "text-white"
          }`}
        >
          <p className="w-11/12 text-sm sm:text-base md:text-lg 2xl:text-2xl 4xl:w-4/5 4xl:text-2xl">
            {description}
          </p>
        </div>
      </div>
    </div>
  );
}

export default CollapsibleCard;
