import HeaderButton from "../components/buttons/HeaderButton";
import BlogPostCard from "../components/cards/BlogPostCard";
import MiniTitle from "../components/sections/MiniTitle";
import OverlayText from "../components/sections/OverlayText";
import SectionContainer from "../components/sections/SectionContainer";
import blogImage from "../../assets/images/blog-image.jpg";

function BlogView() {
  return (
    <SectionContainer darkMode={false} id={"blog"}>
      <div className="relative z-10 flex flex-col">
        <MiniTitle
          title={"Blogs"}
          darkMode={false}
          divClass={"justify-center lg:justify-start"}
        />
        <div className="flex justify-center lg:justify-between">
          <p className="z-20 text-4xl font-bold text-primaryBlack sm:text-5xl lg:text-6xl">
            From My <br /> <span className="text-primaryViolet">Blog Post</span>
          </p>
          <div className="z-30">
            <HeaderButton title={"View all blogs"} darkBg={false} />
          </div>
        </div>

        <OverlayText
          text={"My Blog Post"}
          textClass={
            "-left-0 lg:-left-6 text-primaryGray opacity-40 text-7xl lg:text-left xl:text-8xl"
          }
        />
      </div>

      <div className="h-10"></div>

      <div className="flex flex-col space-y-6 overflow-hidden lg:flex-row lg:justify-between lg:space-x-6 lg:space-y-0 3xl:space-x-12">
        <BlogPostCard
          image={blogImage}
          tags={["Web", "Trends"]}
          title={"The Future of Web Development: Trends to Watch in 2024"}
          author={"Raul Palade"}
          date={"17 Mar 2024"}
        />
        <BlogPostCard
          image={blogImage}
          tags={["Psycology", "App"]}
          title={
            "The Psychology of Design: Creating User Experiences That Resonate"
          }
          author={"Raul Palade"}
          date={"22 Aug 2024"}
        />
        <BlogPostCard
          image={blogImage}
          tags={["Mobile", "UI/UX"]}
          title={"Top 10 Features for a Successful Mobile App: What Users Want"}
          author={"Raul Palade"}
          date={"10 Ott 2024"}
        />
      </div>
    </SectionContainer>
  );
}

export default BlogView;
