import { useState } from "react";
import PrimaryButton from "../buttons/PrimaryButton";
import toast from "react-hot-toast";

function ContactForm() {
  const [formData, setFormData] = useState({
    name: "",
    surname: "",
    email: "",
    phone: "",
    subject: "",
    message: "",
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const mailIsValid = (email: string) => {
    const mailformat =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email.match(mailformat);
  };

  const clearFields = () => {
    setFormData({
      name: "",
      surname: "",
      email: "",
      phone: "",
      subject: "",
      message: "",
    });
  };

  const successToast = (message: string) => {
    toast.success(message);
  };

  const failToast = (message: string) => {
    toast.error(message);
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();

    if (!mailIsValid(formData.email)) {
      failToast("Please enter a valid email");
      return;
    }

    for (const key in formData) {
      if (formData[key as keyof typeof formData] === "") {
        failToast("Fill all fields in the form");
        return;
      }
    }

    const data = {
      "first-name": formData.name,
      "last-name": formData.surname,
      email: formData.email,
      "phone-number": formData.phone,
      message: formData.message,
      access_key: process.env.REACT_APP_WEB3FORM_API_KEY,
    };

    try {
      const response = await fetch("https://api.web3forms.com/submit", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      });

      const resData = await response.json();

      if (resData.success) {
        successToast(resData.message || "Message sent successfully");
        clearFields();
      } else {
        failToast(resData.message || "Unable to send message");
      }
    } catch (error) {
      failToast("Unable to send message");
    }
  };

  return (
    <form onSubmit={handleSubmit} className="w-full space-y-8">
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
        <TextInput
          hint="Name *"
          value={formData.name}
          onChange={handleChange}
          name="name"
        />
        <TextInput
          hint="Surname *"
          value={formData.surname}
          onChange={handleChange}
          name="surname"
        />
      </div>
      <div className="flex flex-col space-y-8 lg:flex-row lg:space-x-8 lg:space-y-0">
        <TextInput
          hint="Email *"
          value={formData.email}
          onChange={handleChange}
          name="email"
        />
        <TextInput
          hint="Phone *"
          value={formData.phone}
          onChange={handleChange}
          name="phone"
        />
      </div>
      <TextInput
        hint="Subject *"
        value={formData.subject}
        onChange={handleChange}
        name="subject"
      />
      <div className="flex flex-col">
        <textarea
          value={formData.message}
          onChange={handleChange}
          name="message"
          className="h-52 rounded-3xl bg-cardLightViolet py-8 pl-8 text-white placeholder:text-white sm:text-lg 4xl:text-2xl"
          placeholder="Message *"
        />
      </div>
      <div className="z-30">
        <PrimaryButton title={"Send message"} onClick={() => handleSubmit} />
      </div>
    </form>
  );
}

const TextInput: React.FC<{
  hint: string;
  value: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  name: string;
}> = ({ hint, value, onChange, name }) => {
  return (
    <div className="flex flex-grow flex-col">
      <input
        type="text"
        value={value}
        onChange={onChange}
        name={name}
        className="rounded-3xl border-0 bg-cardLightViolet py-6 pl-8 text-white placeholder:text-white sm:text-lg 4xl:py-8 4xl:text-2xl"
        placeholder={hint}
      />
    </div>
  );
};

export default ContactForm;
