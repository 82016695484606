import arrowRight from "../../../assets/icons/arrow-right.svg";

function HeaderButton({ title, darkBg }: { title: string; darkBg: boolean }) {
  return (
    <button
      className={`group relative hidden items-center justify-center overflow-hidden rounded-full ${darkBg ? "bg-white" : "bg-primaryViolet"} p-4 px-12 py-4 text-xl font-medium shadow-md transition duration-300 ease-out lg:inline-flex`}
    >
      {/* Overlay per l'hover */}
      <div className="ease absolute inset-0 flex h-full w-full -translate-x-full items-center justify-center bg-primaryGreen font-bold text-primaryBlack duration-300 group-hover:translate-x-0">
        <img src={arrowRight} alt="arrow" className="p-3" />
        <p>{title}</p>
      </div>

      {/* Contenuto di base */}
      <div
        className={`ease absolute flex h-full w-full transform items-center justify-center whitespace-nowrap font-bold ${darkBg ? "text-primaryViolet" : "text-white"} transition-all duration-300 group-hover:translate-x-full`}
      >
        {title}
        <img
          src={arrowRight}
          alt="arrow"
          className={`p-2 ${darkBg ? "" : "invert filter"}`}
        />
      </div>

      <span className="invisible relative">{title}</span>
    </button>
  );
}

export default HeaderButton;
