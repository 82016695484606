import mobileDev from "../../assets/images/mobile-development.png";
import webDev from "../../assets/images/web-development.png";
import aiDev from "../../assets/images/ai-development.png";
import ServiceCard from "../components/cards/ServiceCard";
import MiniTitle from "../components/sections/MiniTitle";
import OverlayText from "../components/sections/OverlayText";
import SectionContainer from "../components/sections/SectionContainer";
import HeaderButton from "../components/buttons/HeaderButton";

function ServiceView() {
  return (
    <SectionContainer darkMode={true} id={"services"}>
      <div className="relative z-10 flex flex-col space-y-3">
        <MiniTitle
          title={"Services"}
          darkMode={true}
          divClass={"justify-center lg:justify-start"}
        />
        <div className="flex justify-center lg:justify-between">
          <p className="text-4xl font-bold sm:text-5xl lg:text-6xl">
            <span className="text-white">My</span>{" "}
            <span className="text-primaryGreen">Services</span>
          </p>
          <div className="z-30">
            <HeaderButton title={"View all services"} darkBg={true} />
          </div>
        </div>

        <OverlayText
          text={"SERVICES"}
          textClass={
            "-left-0 lg:-left-6 text-white opacity-5 text-7xl lg:text-left xl:text-8xl"
          }
        />
      </div>

      <div className="h-10"></div>

      <div className="flex flex-col space-y-6 overflow-hidden lg:flex-row lg:justify-between lg:space-x-6 lg:space-y-0 3xl:space-x-12">
        <ServiceCard
          icon={mobileDev}
          title="App Development"
          description="Unique, high-performing apps that get the job done with style!"
        />
        <ServiceCard
          icon={aiDev}
          title="AI Development"
          description="Intelligent, fun, and always up-to-date AI solutions just for you!"
        />
        <ServiceCard
          icon={webDev}
          title="Web Development"
          description="Amazing websites, pixel-perfect, designed to impress and perform flawlessly!"
        />
      </div>
    </SectionContainer>
  );
}

export default ServiceView;
