import arrowTopRight from "../../../assets/icons/arrow-top-right.svg";

function BlogPostCard({
  image,
  tags,
  title,
  author,
  date,
}: {
  image: any;
  tags: string[];
  title: string;
  author: string;
  date: string;
}) {
  return (
    <div className="flex flex-col space-y-4">
      <div className="group relative">
        <img src={image} alt="blog post" className="rounded-3xl" />
        <div className="absolute inset-0 flex items-center justify-center rounded-3xl bg-black bg-opacity-50 opacity-0 transition-opacity duration-300 group-hover:opacity-100">
          <button className="flex items-center justify-center rounded-full bg-primaryGreen p-4 text-white">
            <img src={arrowTopRight} alt="arrow" className="w-8 stroke-2" />
          </button>
        </div>
      </div>
      <div className="flex space-x-1 2xl:space-x-4">
        {tags.map((tag, index) => (
          <div
            key={index}
            className="rounded-full bg-primaryGreen px-4 py-1 text-sm xs:text-base sm:text-lg md:text-xl 2xl:py-2"
          >
            <p className="text-clip">{tag}</p>
          </div>
        ))}
      </div>
      <p className="font-bold text-primaryBlack xs:text-xl lg:text-lg xl:text-xl 2xl:text-2xl">
        {title}
      </p>
      <div className="flex items-center justify-between">
        <BlogCardDetail value={author} />
        <BlogCardDetail value={date} />
      </div>
    </div>
  );
}

const BlogCardDetail: React.FC<{
  value: string;
}> = ({ value }) => {
  return (
    <div className="flex items-center space-x-2">
      <div className="h-1 w-1 rounded-full bg-primaryViolet lg:p-1"></div>
      <p className="text-sm text-textGray xs:text-lg lg:text-sm xl:text-lg 2xl:text-xl">
        {value}
      </p>
    </div>
  );
};

export default BlogPostCard;
