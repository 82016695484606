import { useNavigate } from "react-router-dom";
import PrimaryButton from "../components/buttons/PrimaryButton";

export function NotFoundView() {
  const navigate = useNavigate();

  return (
    <div className="flex h-screen flex-col items-center justify-center space-y-10 px-4 py-8 sm:px-6 md:px-9 md:py-12 lg:px-12 lg:py-16 xl:px-20 xl:py-20 3xl:py-24 4xl:px-36">
      <p className="text-center text-8xl font-bold text-primaryViolet">404</p>
      <h1 className="text-center text-3xl font-bold tracking-tight text-primaryBlack sm:text-5xl">
        Pagina non trovata
      </h1>
      <p className="text-center text-lg text-textGray">
        Ops, non siamo riusciti a trovare la pagina che stavi cercando.
      </p>
      <div className="px-4">
        <PrimaryButton
          title={"Torna alla home"}
          onClick={() => navigate("/")}
        />
      </div>
    </div>
  );
}
