import { useState } from "react";
import check from "../../../assets/icons/check.svg";
import checkViolet from "../../../assets/icons/check_violet.svg";
import arrowTopRight from "../../../assets/icons/arrow-top-right.svg";

function PlanCard({
  plan,
  price,
  benefits,
}: {
  plan: string;
  price: string;
  benefits: string[];
}) {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div
      className="group w-full flex-grow flex-col justify-between space-y-4 rounded-xl border-2 border-gray-200 bg-white p-8 transition duration-300 hover:border-transparent hover:bg-primaryViolet xl:flex-1 xl:flex-grow"
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <div className="flex items-center justify-between">
        <p className="text-2xl font-bold text-primaryBlack transition duration-300 group-hover:text-white">
          {plan}
        </p>
        <div className="rounded-full bg-primaryGray p-4 font-semibold transition duration-300 group-hover:bg-cardLightViolet">
          <img
            src={arrowTopRight}
            alt="arrow"
            className="w-5 stroke-2 filter transition duration-300 group-hover:invert 2xl:w-8"
          />
        </div>
      </div>
      <p className="pb-6 text-primaryBlack transition duration-300 group-hover:text-white 2xl:text-2xl">
        <span className="text-4xl font-extrabold text-primaryViolet transition duration-300 group-hover:text-white 2xl:text-6xl">
          ${price}
        </span>{" "}
        / Hour
      </p>
      <div className="flex flex-col space-y-6 text-xl 4xl:text-2xl">
        {benefits.map((benefit, index) => (
          <div key={index} className="flex items-center space-x-4">
            <img
              src={isHovered ? check : checkViolet}
              alt="check"
              className={`w-5 transition duration-300 2xl:w-7 ${isHovered ? "invert" : ""}`}
            />
            <p className="text-primaryBlack transition duration-300 group-hover:text-white">
              {benefit}
            </p>
          </div>
        ))}
      </div>
    </div>
  );
}

export default PlanCard;
