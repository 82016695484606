import HeaderButton from "../components/buttons/HeaderButton";
import PlanCard from "../components/cards/PlanCard";
import MiniTitle from "../components/sections/MiniTitle";
import OverlayText from "../components/sections/OverlayText";
import SectionContainer from "../components/sections/SectionContainer";

function PricingView() {
  return (
    <SectionContainer darkMode={false} id={"pricing"}>
      <div className="relative z-10 flex flex-col space-y-3">
        <MiniTitle
          title={"Pricing Table"}
          darkMode={false}
          divClass={"justify-center lg:justify-start"}
        />
        <p className="z-20 overflow-hidden text-center text-4xl font-bold text-primaryBlack sm:text-5xl lg:text-left lg:text-6xl xl:text-5xl 2xl:text-6xl">
          My{" "}
          <span className="text-primaryViolet">
            Pricing <br className="hidden lg:block" /> Model
          </span>
        </p>
        <OverlayText
          text={"My Pricing Model"}
          textClass={
            "-left-6 text-4xl text-primaryGray lg:text-left opacity-40 md:text-6xl 2xl:text-8xl"
          }
        />
      </div>

      <div className="h-10"></div>

      <div className="z-10 flex flex-col space-y-6 overflow-hidden xl:-mt-48 xl:flex-row xl:items-end xl:justify-between xl:space-x-10 xl:space-y-0 3xl:space-x-12">
        <div className="flex flex-col justify-between space-y-4 rounded-xl border-2 border-gray-200 p-8 xl:flex-1 xl:flex-grow">
          <p className="text-2xl text-primaryBlack sm:text-3xl">
            Hey, Need Custom Plans?
          </p>
          <p className="text-xl text-textGray">
            Tailor a plan that fits your unique development needs! Work directly
            together to create the ideal solution for your web, app, or AI
            project.
          </p>
          <div className="z-30 pt-1">
            <HeaderButton title={"Get started"} darkBg={false} />
          </div>
        </div>

        <PlanCard
          plan="Web"
          price="40"
          benefits={[
            "Responsive Design",
            "Static Website",
            "Web Application",
            "E-commerce Functionality",
            "Content Management Systems",
          ]}
        />

        <PlanCard
          plan="Mobile"
          price="50"
          benefits={[
            "Native/Hybrid Development",
            "Offline Functionalities",
            "App Store Deployment",
            "User Analytics",
            "Multilanguage Support",
            "Accessibility Features",
            "Real-Time Features",
          ]}
        />
      </div>
    </SectionContainer>
  );
}

export default PricingView;
