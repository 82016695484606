import Footer from "../components/sections/Footer";
import BlogView from "./BlogView";
import ContactView from "./ContactPage";
import FAQView from "./FAQView";
import HomeView from "./HomeView";
import PricingView from "./PricingView";
import ProjectsView from "./ProjectsView";
import ServiceView from "./ServiceView";
import WorkEducationView from "./WorkEducationView";

function RootView() {
  return (
    <div>
      <HomeView />
      <ServiceView />
      <WorkEducationView />
      <ProjectsView />
      <PricingView />
      <ContactView />
      <BlogView />
      <FAQView />
      <Footer />
    </div>
  );
}
export default RootView;
