function ExperienceCard({
  icon,
  title,
  educationEntry,
}: {
  icon: any;
  title: string;
  educationEntry: { place: string; detail: string; years: string }[];
}) {
  return (
    <div className="flex w-full flex-col rounded-xl bg-secondaryGray p-5 lg:p-8">
      <div className="flex items-center space-x-3 p-3 xl:space-x-6">
        <div className="rounded-full bg-primaryViolet p-4 3xl:p-5">
          <img
            src={icon}
            alt="Web development icon"
            className="w-6 invert filter transition duration-300 group-hover:invert-0 md:w-8 lg:w-10 xl:w-14"
          />
        </div>
        <p className="text-xl font-bold text-primaryViolet lg:text-2xl xl:text-3xl 3xl:text-4xl">
          {title}
        </p>
      </div>
      <div className="w-full border-primaryGray 3xl:mb-6 3xl:mt-4 3xl:border-t-2"></div>

      <div className="flex flex-col space-y-3 p-3 3xl:space-y-6">
        {educationEntry.map((entry) => (
          <EducationEntry
            key={entry.place}
            place={entry.place}
            detail={entry.detail}
            years={entry.years}
          />
        ))}
      </div>
    </div>
  );
}

const EducationEntry = ({
  place,
  detail,
  years,
}: {
  place: string;
  detail: string;
  years: string;
}) => (
  <div className="flex-col">
    <div className="flex items-center justify-between">
      <p className="text-sm sm:text-lg md:text-xl lg:text-2xl 3xl:text-3xl">
        {place}
      </p>
      <div className="rounded-lg bg-white p-1 text-sm sm:text-base md:text-lg lg:text-xl 3xl:p-1.5 3xl:text-lg">
        {years}
      </div>
    </div>
    <p className="text-sm text-textGray sm:text-base md:text-xl lg:text-2xl 3xl:text-xl">
      {detail}
    </p>
  </div>
);

export default ExperienceCard;
